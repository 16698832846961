import { pyApi } from '@/api/request'
import * as model from '../models'

// 列表
export function offlineList(params: object): Promise<model.OfflineListInfo> {
  return pyApi({
    method: 'GET',
    url: '/fairy/api/v1/offline_courses/',
    params
  })
}

// 详情
export function getOfflineDetail(id: string): Promise<model.OfflineDetailInfo> {
  return pyApi({
    method: 'GET',
    url: `/fairy/api/v1/offline_courses/${id}/`
  })
}
