



































import { Component, Vue, Watch } from 'vue-property-decorator'

import ClassTracking from '@/modules/course/pages/course-class/class-tracking'

import { getOfflineDetail } from '../api'
import * as model from '../models'
import { getPintuanDetail } from '@/modules/common/api/index.ts'
import { checkLogin } from '@/utils/user'
import DetailHeader from '@/modules/common/components/detail-header.vue'
import navTab from '@/modules/common/components/nav-tab.vue'
import ueditorDisplay from '@/modules/common/components/ueditor-display/index.vue'
// import extLearning from '@/modules/course/pages/course-detail/components/ext-learning.vue'

@Component({
  components: {
    DetailHeader,
    navTab,
    ueditorDisplay
  }
})

export default class OfflineDetailIndex extends Vue {
  isLoading: boolean = true
  offlineDetail: model.OfflineInfo = {}
  pintuanDetail: any = {}
  errorObj: any = null
  tabList: Array<object> = [
    { name: '介绍', value: 'introduce' }
  ]
  activeTab: string = 'introduce'

  mounted() {
    this.getDetail()
  }

  get isShow() {
    let time = this.offlineDetail.registration_deadline
    return time && time > (+new Date() / 1000)
  }

  getDetail() {
    let id = this.$route.params.id
    return getOfflineDetail(id).then(res => {
      if (!res.error) {
        res.type = 'offlinecourse'
        this.offlineDetail = res
        if (res.fightgroup) {
          this.getPintuanDetail()
        }
      } else {
        let errorText = res.message || '服务器异常'
        this.errorObj = {
          html: errorText
        }
      }
      this.isLoading = false
    })
  }

  // 获取拼团活动详情
  getPintuanDetail() {
    if (!checkLogin()) return
    let id = this.offlineDetail.fightgroup
    getPintuanDetail(id).then((res): void => {
      this.pintuanDetail = res
    })
  }
}
